import { Instance, SnapshotOut, types as t } from 'mobx-state-tree';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import model from './model';
import views from './views';
import actions from './actions';

const Range = model.views(views).actions(actions);

export interface IRange extends Instance<typeof Range> {}
export interface IRangeOut extends SnapshotOut<typeof Range> {}

export default t.snapshotProcessor(Range, {
  preProcessor(range: IRangeOut) {
    if (range && typeof range.from === 'string') {
      const [n, type] = range.from.split('_');
      return {
        from: { [type]: Number(n) }
      };
    }
    return range;
  },
  postProcessor(sn) {
    if (!sn.to) {
      return omit(sn, 'to', 'timezone');
    }
    return pick(sn, 'from', 'to', 'is_pinned');
  }
});
