import { types as t, Instance } from 'mobx-state-tree';
import Organization from '~/mst/models/organization';
import Fetchable from '~/mst/models/abstract/fetchable';
import Filterable from '../abstract/filterable';
import CollectionMap from '../abstract/collection_map';

const OrganizationsModel = t.compose(
  CollectionMap(Organization),
  Fetchable({
    cache: { lifeTime: 300 }
  }),
  Filterable
);

export interface IOrganizationsModel extends Instance<typeof OrganizationsModel> {}

export default OrganizationsModel;
