import React, { useCallback } from 'react';
import { Stack, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import { PERMISSIONS } from '~/mst/models/permissions/types';
import useAuth from '~/hooks/use_auth';
import useNavigate from '~/hooks/use_navigate';
import useMst from '~/hooks/use_mst';
import RangeSelector from '~/components/range_selector';
import NavigateDropdown from '~/components/navigate_dropdown';
import Actions from './actions';
import AddWidgetsContainer from '../add_widget';

type DashboardHeaderProps = {
  widgetSidebarVisible: boolean;
  handleAddWidget: () => void;
};

const ranges = [
  { value: { from: { minutes: 30 } } },
  { value: { from: { hours: 1 } } },
  { value: { from: { hours: 2 } } },
  { value: { from: { hours: 3 } } },
  { value: { from: { hours: 4 } } },
  { value: { from: { hours: 6 } } },
  { value: { from: { hours: 12 } } },
  { value: { from: { days: 1 } } }
];

function DashboardHeader({ widgetSidebarVisible, handleAddWidget }: DashboardHeaderProps) {
  const { dashboards } = useMst();
  const { navigate } = useNavigate();
  const { auth } = useAuth();

  const { id } = useParams();
  const dashboard = dashboards.getById(id);
  const toggleIsStarred = useCallback(() => {
    dashboard.toggleIsStarred();
  }, [dashboard]);
  const handleChangeRange = useCallback(
    (newRange) => {
      dashboard.setRange(JSON.parse(newRange));
    },
    [dashboard]
  );

  if (!dashboard) {
    return null;
  }

  return (
    <Stack sx={{ pt: 0.5, mb: 2 }} alignItems="flex-start" justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={toggleIsStarred}>{dashboard?.is_starred ? <StarRateIcon /> : <StarOutlineIcon />}</IconButton>
        <NavigateDropdown
          options={dashboards.valueLabelPairs}
          width={{ multiplier: 1.5, extra: 50 }}
          onChange={(value) => navigate(`dashboards/${value}`)}
          loading={dashboards.isSyncing}
          value={id}
        />
      </Stack>
      <Stack direction="column" alignItems="flex-end" justifyContent="flex-end" spacing={0}>
        <RangeSelector
          isDateTimeRangeReadOnly
          value={JSON.stringify(dashboard?.range?.toJSON())}
          isUpdatesOn={dashboard.is_updates_on}
          handleToggleUpdates={() => dashboard.toggleUpdates()}
          onChange={handleChangeRange}
          options={ranges}
          sx={{ minWidth: 380, mr: 1 }}
        />
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
          {auth.user?.hasPermission(PERMISSIONS.ReadReports, dashboard.organization_id) && (
            <>
              <AddWidgetsContainer isVisible={widgetSidebarVisible} handleVisible={handleAddWidget} />
              <Actions />
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default observer(DashboardHeader);
