/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { FormControl, Card } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFormState, useForm } from 'react-final-form';
import { autorun } from 'mobx';

import useLocales from '~/hooks/use_locales';
import { IAvailableDevices } from '~/mst/models/node/available_devices';
import Select from '~/components/final_form/select';
import TextInput from '~/components/final_form/text_input';
import { BodyModule, CardFooter } from '~/pages/device_edit/components';
import { ModulesContainerStyled, HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';
import MCardHeader from '~/components/@material-extend/MCardHeader';

type AddNewSensorFormType = {
  handleSubmit: VoidFunction;
  cancelAddSensorForm: VoidFunction;
  submitting: boolean;
  availableDevices: IAvailableDevices;
};

function AddNewSensorForm({ handleSubmit, cancelAddSensorForm, availableDevices, submitting }: AddNewSensorFormType) {
  const { t } = useLocales();
  const {
    values: { name, com_port: comPort }
  } = useFormState({ subscription: { values: true } });
  const { change, reset } = useForm();
  const device = availableDevices?.getById(name);
  const address = device?.connections.find((connection) => connection.com_port === comPort)?.address;

  useEffect(
    () =>
      autorun(() => {
        const connection = device?.connections[0];
        change('com_port', connection?.com_port);
        change('com_type', connection?.com_type);
      }),
    [device, change]
  );

  useEffect(() => {
    change('address', address);
  }, [address, change]);

  return (
    <Card sx={{ display: 'block' }} data-testid="addNewSensorForm">
      <ModulesContainerStyled>
        <HeaderDeviceWrapperStyled>
          <MCardHeader title={t('thiamis.add_sensor')} />
        </HeaderDeviceWrapperStyled>
        <BodyModule title={t('sensor.title')} columns={1} expandable>
          <FormControl fullWidth>
            <Select
              searchable
              loading={availableDevices?.isFetching}
              name="name"
              label={t('sensor.type')}
              options={availableDevices?.valueLabelPairs}
              data-testid="sensorNameSelect"
            />
          </FormControl>
        </BodyModule>
        <BodyModule title={t('device_monitor.forms.connectivity')} columns={1} expandable>
          <FormControl fullWidth>
            <Select
              name="com_port"
              label={t('device_monitor.forms.com_port')}
              options={device?.comPortValueLabelPairs || []}
              data-testid="sensorComPortSelect"
            />
          </FormControl>
          <FormControl fullWidth>
            <Select
              name="com_type"
              label={t('device_monitor.forms.com_type')}
              options={device?.comTypeValueLabelPairs || []}
              data-testid="sensorComTypeSelect"
            />
          </FormControl>
          {address && (
            <FormControl fullWidth>
              <TextInput name="address" label={t('device_monitor.forms.address')} data-testid="sensorAddressInput" />
            </FormControl>
          )}
        </BodyModule>
      </ModulesContainerStyled>
      <CardFooter
        submitting={submitting}
        handleReset={() => {
          cancelAddSensorForm();
          reset();
        }}
        handleSubmit={handleSubmit}
      />
    </Card>
  );
}

export default observer(AddNewSensorForm);
