/* eslint-disable no-prototype-builtins */
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';
import { DEVICE_STATUSES } from '~/utils/constants';

export function isThiamisDevice(id) {
  return endsWith(id, ':0');
}

export function isThiamis1000Device(id) {
  return startsWith(id, '0B');
}

export function isAirthinxDevice(id) {
  return /[0-9]{1}C[0-9A-Z]{6}/.test(id);
}

export function isAethairProDevice(id) {
  return /2C[0-9A-Z]{6}/.test(id);
}

export function isHealthwayDevice(id: string) {
  return startsWith(id, 'A1');
}

export function getOnlineStatusFromAQ(status: string) {
  if ([DEVICE_STATUSES.GOOD, DEVICE_STATUSES.MODERATE, DEVICE_STATUSES.POOR, DEVICE_STATUSES.ONLINE].includes(status)) {
    return DEVICE_STATUSES.ONLINE;
  }
  return DEVICE_STATUSES.OFFLINE;
}
