/* eslint-disable no-param-reassign */
import { types as t } from 'mobx-state-tree';
import omit from 'lodash/omit';
import Range from '~/mst/models/range';

export abstract class CustomLocalStorage {
  public static getItem(key: string) {
    const json = localStorage.getItem(key);
    const data = JSON.parse(json);
    if (data.is_pinned) {
      return json;
    }
    return JSON.stringify({ is_pinned: false });
  }

  public static setItem(key: string, data: string) {
    return localStorage.setItem(key, data);
  }
}

const ReportModel = t
  .model({
    is_updates_on: t.optional(t.boolean, true),
    range: t.optional(Range, { from: { hours: 3 } })
  })
  .actions((self) => ({
    setRange(range) {
      self.range = { ...omit(self.range, 'from', 'to'), ...range };
    },
    toggleUpdates() {
      self.is_updates_on = !self.is_updates_on;
      if (self.is_updates_on) {
        self.fetch();
      }
    },
    turnOffUpdates() {
      self.is_updates_on = false;
    }
  }));

export default ReportModel;
