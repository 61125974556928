/* eslint-disable */
import humanizeDuration from 'humanize-duration';

const HumanizeDuration = (superclass) =>
  class extends superclass {
    getHumanizer(options = {}) {
      if (this.humanizer) {
        return this.humanizer;
      }
      this.humanizer = humanizeDuration.humanizer({
        fallbacks: ['en'],
        ...options
      });
      return this.humanizer;
    }

    humanizeDuration(valueInMins: number, props?: any) {
      const { smallestUnit, language, units = ['y', 'mo', 'd', 'h', 'm'] } = props || {};
      if (smallestUnit) {
        // TODO code full logic for different units
        if (valueInMins < 60 * 1000) return '<1min';
      }
      return this.getHumanizer({ language })(valueInMins, {
        language: 'shortEn',
        languages: {
          shortEn: {
            y: () => 'y',
            mo: () => 'mo',
            w: () => 'w',
            d: () => 'd',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms'
          }
        },
        spacer: '',
        units,
        largest: 1,
        round: true,
        ...props
      });
    }
    humanizeDurationLong(valueInMins: number, props?: any) {
      const { units = ['y', 'mo', 'd', 'h', 'm'] } = props || {};
      return this.getHumanizer({ language: 'en' })(valueInMins, {
        spacer: ' ',
        units,
        largest: 1,
        round: true,
        ...props
      });
    }
  };

class Humanizer extends HumanizeDuration(class {}) {}
const humanizer = new Humanizer(class {});

export default humanizer;
