/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import debounce from 'lodash/debounce';
import { useTheme } from '@mui/material/styles';
import { getZones } from '~/components/chart/chart_options';
import { STATUS_COLORS } from '~/theme/types';

import { IDataPointNode } from '~/mst/models/data_point/node/default';
import { DEVICE_STATUSES } from '~/utils/constants';
import chartSettings from './options';

HighchartsBoost(Highcharts);

type TrendlineType = {
  data: Array<any>;
  dataPoint: IDataPointNode;
  showZones?: boolean;
};

function Trendline({ dataPoint, data = [], showZones }: TrendlineType) {
  const theme = useTheme();
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const containerRef = useRef(null);

  const resizeObserver = useRef(
    new ResizeObserver(
      debounce(() => {
        if (chartRef.current) {
          chartRef.current.chart.reflow();
        }
      }, 250)
    )
  );

  useEffect(() => {
    const resizeObserverInstance = resizeObserver.current;
    const containerRefInstance = containerRef.current;
    if (containerRefInstance) {
      resizeObserverInstance.observe(containerRefInstance);
    }
    return () => {
      if (containerRefInstance) {
        resizeObserverInstance.unobserve(containerRefInstance);
      }
    };
  }, [containerRef, resizeObserver]);

  return (
    <Box ref={containerRef} sx={{ width: '100%', height: '100%' }}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="stockChart"
        containerProps={{ style: { height: '100%', maxHeight: '40px' } }}
        options={{
          ...chartSettings,
          series: [
            {
              ...chartSettings.series[0],
              color: showZones ? STATUS_COLORS[DEVICE_STATUSES.ONLINE] : theme.palette.grey[500],
              data: [...data],
              round: dataPoint?.precision || 0,
              zones: showZones && dataPoint?.hasProfileRange ? getZones(dataPoint.profileRange, dataPoint.profile.status_name) : [],
              // boostThreshold: 1,
              name: dataPoint.presentName
            }
          ]
        }}
        allowChartUpdate
      />
    </Box>
  );
}

export default Trendline;
