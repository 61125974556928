import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Form as FinalForm } from 'react-final-form';
import { Box } from '@mui/material';
import pick from 'lodash/pick';

import { INode } from '~/mst/models/node';
import { IAvailableDevices } from '~/mst/models/node/available_devices';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import useFetch from '~/hooks/use_fetch';

import { captureException } from '~/services/sentry';
import LoadingBox from '~/components/loading_box';
import AddNewSensorForm from './new_sensor_form';

type AddNewSensorContainerProps = {
  node: INode;
  availableDevices: IAvailableDevices;
  cancelAddSensorForm: VoidFunction;
};

function AddNewSensorContainer({ availableDevices, node, cancelAddSensorForm }: AddNewSensorContainerProps) {
  const { t } = useLocales();

  useFetch(availableDevices, pick(node.toJSON(), '_id', 'organization_id', 'serial', 'devices'));

  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        await node.devices.create(values);
        cancelAddSensorForm();
        toasts.success(t('thiamis.manage_dialog.sensor_added'));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [cancelAddSensorForm, node, t]
  );

  if (availableDevices.isFetching) {
    return <LoadingBox />;
  }

  return (
    <Box sx={{ mb: 3 }}>
      <FinalForm onSubmit={handleOnSubmit} subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit }) => (
          <AddNewSensorForm
            availableDevices={availableDevices}
            submitting={node.devices?.isSyncing}
            handleSubmit={handleSubmit}
            cancelAddSensorForm={cancelAddSensorForm}
          />
        )}
      </FinalForm>
    </Box>
  );
}

export default observer(AddNewSensorContainer);
