/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, Suspense, useMemo } from 'react';
import { Stack, CircularProgress } from '@mui/material';
import { Form as FinalForm } from 'react-final-form';
import { computed } from 'mobx';
import kebabCase from 'lodash/kebabCase';
import { observer } from 'mobx-react-lite';
import { ModulesContainerStyled } from '~/pages/device_edit/components/styled';
import FormContainer from '~/pages/device_edit/containers/form_container';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import { useValidationSchema } from '~/hooks/use_validate';
import { ISensor } from '~/mst/models/device/sensor';

import { captureException } from '~/services/sentry';
import getSensorArtefacts from './sensors';
import { SensorContainer } from './sensors/components';
import Maintenance from './sensors/sensor/common/maintenance';
import SensorActions from './actions';
import SensorErrors from './errors';
import SensorParameters from './parameters';

function DeviceSensor({ sensor }: { sensor: ISensor }) {
  const { t } = useLocales();
  const { component: SensorComponent, schema } = getSensorArtefacts(kebabCase(sensor.name));
  const validate = useValidationSchema(schema);
  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        await sensor.update(sensor.parseFormValues(values));
        toasts.success(t(`notifications.success.forms.update_sensor`));
      } catch (e) {
        captureException(e);
        toasts.error(t('notifications.errors.server_error'));
      }
    },
    [sensor, t]
  );

  const initialValues = useMemo(() => computed(() => sensor.formValues), [sensor]).get();

  return (
    <FinalForm
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validate={validate}
      subscription={{ submitting: true, pristine: true, dirty: true }}
    >
      {({ handleSubmit }) => (
        <FormContainer
          title={sensor.modelName}
          tag={sensor.tag}
          statusType={sensor.statusType()}
          statusText={sensor.statusText()}
          handleSubmit={handleSubmit}
          model={sensor}
          data-testid={`${sensor.modelName}SensorForm`}
          actions={sensor.isAethair === false && <SensorActions sensor={sensor} />}
        >
          <ModulesContainerStyled>
            <Suspense
              fallback={
                <Stack alignItems="center">
                  <CircularProgress size={25} color="info" />
                </Stack>
              }
            >
              <SensorContainer sensor={sensor}>
                <SensorComponent sensor={sensor} />
                <SensorErrors sensor={sensor} />
                {sensor.isAethair === false && <SensorParameters sensor={sensor} />}
                <Maintenance sensor={sensor} />
              </SensorContainer>
            </Suspense>
          </ModulesContainerStyled>
        </FormContainer>
      )}
    </FinalForm>
  );
}

export default observer(DeviceSensor);
