import { SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { ButtonGroup, Paper, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonGroupStyled = styled(ButtonGroup)(({ theme }) => ({
  minHeight: 40,
  [theme.breakpoints.down('sm')]: {
    minHeight: 38
  },
  '& > .Mui-disabled': {
    cursor: 'not-allowed',
    borderColor: 'var(--variant-outlinedBorder, currentColor) !important'
  }
}));

export const PaperStyled = styled(Paper)(() => ({
  position: 'absolute',
  top: 45,
  zIndex: 10,
  minWidth: 230
}));

export const SingleInputDateTimeRangeFieldStyled = styled(SingleInputDateTimeRangeField)(({ theme }) => ({
  minWidth: 380,
  [theme.breakpoints.down('sm')]: {
    minWidth: 350,
    '.MuiChip-root': {
      marginRight: theme.spacing(0.5),
      '.MuiChip-label': {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
      }
    },
    '.MuiInputBase-root': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  }
}));

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  minWidth: 380,
  [theme.breakpoints.down('sm')]: {
    minWidth: 350,
    '.MuiChip-root': {
      '.MuiChip-label': {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
      }
    }
  }
}));

export const StaticDateTimePickerStyled = styled(StaticDateTimePicker)(({ theme }) => ({
  position: 'absolute',
  zIndex: 11,
  boxShadow: theme.palette.themeShadows.card,
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: 1
}));
