import { types as t, Instance } from 'mobx-state-tree';
import { ModelV1 as Model } from '~/mst/models/abstract/model';
import Fetchable from '~/mst/models/abstract/fetchable';

const DataPointModel = t.compose(
  t.model({
    measurements: t.frozen([])
  }),
  Model,
  Fetchable({ cache: true })
);

export interface IDataPointModel extends Instance<typeof DataPointModel> {}

export default DataPointModel;
