import React, { useCallback } from 'react';
import { IconButton, MenuItem, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import useLocales from '~/hooks/use_locales';
import useModal from '~/hooks/use_modal';

import useActionsPopover from '~/hooks/react_grid/use_actions_popover';
import useMst from '~/hooks/use_mst';
import type { IDashboard } from '~/mst/models/dashboard';
import ShareModal from '~/pages/dashboard_view/header/share_modal';

const ICON = {
  mr: 2,
  width: 20,
  height: 20
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DashboardActions({ dashboard }: { dashboard: IDashboard }) {
  const { t } = useLocales();
  const { ActionsMenu, handleClose, handleOpen, open } = useActionsPopover();
  const { open: shareOpen, handleClose: handleShareClose, handleOpen: handleShareOpen } = useModal();
  const { dashboards } = useMst();

  const { canBeShared } = dashboard;

  const handleDelete = useCallback(
    async (event) => {
      event.stopPropagation();
      await dashboards.destroy(dashboard);
      handleClose();
    },
    [handleClose, dashboards, dashboard]
  );

  const handleShare = useCallback(
    (event) => {
      event.stopPropagation();
      handleShareOpen();
      handleClose();
    },
    [handleClose, handleShareOpen]
  );

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ visibility: open ? 'visible' : 'auto' }}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu>
        {canBeShared && (
          <>
            <MenuItem onClick={handleShare}>
              <ShareIcon sx={ICON} />
              {t('base.buttons.share')}
            </MenuItem>
            <Divider />
          </>
        )}
        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <DeleteIcon sx={ICON} />
          {t('base.buttons.delete')}
        </MenuItem>
      </ActionsMenu>
      <ShareModal open={shareOpen} handleModalClose={handleShareClose} dashboard={dashboard} />
    </>
  );
}

DashboardActions.defaultProps = {};

export default DashboardActions;
