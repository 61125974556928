import React, { useState, useMemo } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Stack, CircularProgress, Box } from '@mui/material';

import useNavigate from '~/hooks/use_navigate';
import Breadcrumbs from '~/components/breadcrumbs';
import useMst from '~/hooks/use_mst';
import useLocales from '~/hooks/use_locales';
import useFetch from '~/hooks/use_fetch';
import { ExportProvider } from '~/hooks/react_grid/use_export';
import { ChartProvider } from '~/hooks/use_chart';
import { ActionProvider } from '~/components/actions_dropdown/context';
import NavigateDropdown from '~/components/navigate_dropdown/alerts_dropdown';
import type { IAlert } from '~/mst/models/alert';
import { SelectionProvider } from '~/hooks/react_grid/use_selection';
import Container from '~/components/container';
import ActionsContainer from './actions_container';
import RangeSelector from './chart_table_block/range_select';
import Layout from './layout';
import AlertMonitor from './store';

function AlertMonitorPage() {
  const { t } = useLocales();
  const { id } = useParams();
  const { alerts } = useMst();
  const { navigate } = useNavigate();

  const { isFetching } = useFetch(alerts);

  const alert: IAlert = useMemo(() => computed(() => alerts.getById(id)), [alerts, id]).get();

  const [monitor] = useState(
    AlertMonitor.create({
      range: {
        from: { days: 1 }
      },
      alert_id: id
    })
  );

  useFetch(alert);

  const links = useMemo(
    () => [
      { title: t('alerts.title'), to: 'alerts' },
      [
        { title: t('alerts.config_page_breadcrumb'), to: `alerts/${id}`, hasAccess: alert?.isModifiable },
        { title: t('alerts.monitor_page_breadcrumb'), to: `alerts/${id}/monitor`, hasAccess: alert?.isMonitorable }
      ]
    ],
    [id, t, alert?.isMonitorable, alert?.isModifiable]
  );

  return (
    <ChartProvider>
      <ExportProvider>
        <Container>
          <Breadcrumbs links={links} />
          <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 2 }}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              <NavigateDropdown
                onChange={(value) => navigate(`alerts/${value}/monitor`)}
                options={alerts.valueLabelPairsSortedByLastTriggeredTs}
                loading={isFetching}
                value={id}
              />
              <Box display="flex" justifyContent="flex-end">
                <Stack direction="column" gap={0}>
                  <RangeSelector monitor={monitor} />
                  <Stack direction="row" gap={1} justifyContent="flex-end">
                    {alert?.isModifiable && (
                      <SelectionProvider selected={[id]}>
                        <ActionProvider>
                          <ActionsContainer alert={alert} />
                        </ActionProvider>
                      </SelectionProvider>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            {alert?.isFetched ? (
              <Layout alert={alert} monitor={monitor} />
            ) : (
              <Stack alignItems="center">
                <CircularProgress size={25} color="info" />
              </Stack>
            )}
          </Stack>
        </Container>
      </ExportProvider>
    </ChartProvider>
  );
}

export default observer(AlertMonitorPage);
