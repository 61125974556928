import React, { useEffect } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFormState, useForm } from 'react-final-form';
import { observable, action } from 'mobx';
import { OnChange } from 'react-final-form-listeners';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/fp/flow';
import first from 'lodash/first';
import map from 'lodash/fp/map';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

import Switch from '~/components/final_form/switch';
import { getOptionsForPayload } from '~/components/final_form/select/utils';
import OrganizationSelect from '~/components/organizations_select';
import useLocales from '~/hooks/use_locales';
import TextInput from '~/components/final_form/text_input';
import HiddenInput from '~/components/final_form/hidden_input';
import FormGrid from '~/components/@material-extend/form_grid';
import useFetch from '~/hooks/use_fetch';
import MCardHeader from '~/components/@material-extend/MCardHeader';
import Select from '~/components/final_form/select';
import DevicesSelect from '~/components/devices_select';
import DateTimePicker from '~/components/final_form/date_time_picker';
import TimePicker from '~/components/final_form/time_picker';
import { HeaderDeviceWrapperStyled } from '~/pages/device_edit/components/styled';
import useMst from '~/hooks/use_mst';
import { PATHS } from '~/utils/constants';
import { IReport } from '~/mst/models/report';

const standardOptions = [{ value: 'aqi', label: 'AQI' }];
const params = observable(
  {
    organization_id: null,
    setOrganizationId(value) {
      this.organization_id = value;
    }
  },
  {
    setOrganizationId: action
  }
);
function EditReportForm({ model }: { model: IReport }) {
  const { t } = useLocales();
  const { nodes, organizations } = useMst();
  const {
    values: {
      organization_id: organizationId,
      configuration: { from, to, working_hours: workingHours }
    }
  } = useFormState({ subscription: { values: true } });

  const { change } = useForm();
  useEffect(() => params.setOrganizationId(organizationId), [organizationId]);
  const { isFetching } = useFetch(nodes, params);

  const now = Date.now();

  return (
    <Card>
      <HeaderDeviceWrapperStyled>
        <MCardHeader title="Settings" />
      </HeaderDeviceWrapperStyled>
      <CardContent>
        <FormGrid rowGap={3}>
          <HiddenInput name="configuration.time_zone" />
          <TextInput name="name" label={t('attributes.report.name')} />
          <FormGrid columns="2">
            <Select options={standardOptions} label={t('attributes.report.standard')} name="configuration.standard" />
            <OrganizationSelect
              data-testid="reportOwnerSelect"
              disabled={!model.isNew}
              options={organizations.valueLabelPairsManagerAccess}
              name="organization_id"
              label={t('attributes.alert.organization_id')}
            />
          </FormGrid>
          <DevicesSelect
            allowSelectAll
            name="configuration.node_ids"
            loading={isFetching}
            label={t('attributes.report.node_ids')}
            options={nodes.monitorableAirthinxValueLabelPairs || []}
            multiple
          />
          <FormGrid columns="2" rowGap={1}>
            <DateTimePicker name="configuration.from" label={t('thiamis.download_csv.from')} maxDateTime={to || now} timezone="UTC" />
            <DateTimePicker name="configuration.to" label={t('thiamis.download_csv.to')} maxDateTime={now} minDate={from} timezone="UTC" />
            <Box>
              <Switch label="Choose working hours" name="configuration.working_hours.enabled" />
            </Box>
            {workingHours?.enabled && (
              <FormGrid columns="2">
                <TimePicker name="configuration.working_hours.from" label={t('thiamis.download_csv.from')} timezone="UTC" />
                <TimePicker name="configuration.working_hours.to" label={t('thiamis.download_csv.to')} timezone="UTC" />
              </FormGrid>
            )}
          </FormGrid>
        </FormGrid>
      </CardContent>
      <OnChange name="configuration.node_ids">
        {async (value) => {
          const nodeIds = getOptionsForPayload(value);
          if (!isEmpty(nodeIds)) {
            await nodes.fetchDataPoints({ last: 1, path: [PATHS.TIMEZONE], node_id: nodeIds });
            const timezone = flow(
              map((id) => nodes.getById(id)?.data_points?.getByPath(PATHS.TIMEZONE)?.lastValue),
              compact,
              uniq,
              first
            )(nodeIds);
            if (timezone) {
              change('configuration.time_zone', timezone);
            }
          }
        }}
      </OnChange>
    </Card>
  );
}

export default observer(EditReportForm);
