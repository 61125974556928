import { types as t, Instance } from 'mobx-state-tree';
import { ModelV2 } from '~/mst/models/abstract/model';
import Syncable from '~/mst/models/abstract/syncable';
import ReportModel from '~/mst/models/abstract/report';
import MaybeString from '~/mst/models/abstract/maybe_string';
import Widgets from '~/mst/models/widgets';

const DashboardModel = t.compose(
  t.model({
    name: t.maybeNull(t.string),
    description: t.maybeNull(MaybeString),
    organization_id: t.maybeNull(t.string),
    user_id: t.maybeNull(t.string),
    widgets: t.optional(Widgets, []),
    is_starred: t.optional(t.boolean, false)
  }),
  ModelV2,
  Syncable,
  ReportModel
);

export interface IDashboardModel extends Instance<typeof DashboardModel> {}

export default DashboardModel;
