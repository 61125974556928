import { Grid, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { INode } from '~/mst/models/node';

import DeviceFormContainer from '~/pages/device_edit/containers/device_form_container';
import PublicDashboardContainer from '~/pages/device_edit/containers/public_dashboard_container';
import AdminSettingsContainer from '~/pages/device_edit/containers/admin_settings_container';
import useAuth from '~/hooks/use_auth';
import SensorFormContainer from '~/pages/device_edit/containers/sensor_form_container';

type EditFormProps = {
  node: INode;
};

function EditForm({ node }: EditFormProps) {
  const { auth } = useAuth();
  const isAdmin = auth.hasAdminDevice(node?.organization_id);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <DeviceFormContainer node={node} />
            </Grid>
            {isAdmin && (
              <Grid item xs={12} md={12} lg={12}>
                <AdminSettingsContainer node={node} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container spacing={2}>
            {node?.devices?.sensors?.map((sensor: ISensor) => (
              <Grid item xs={12} md={12} lg={12} key={sensor._id}>
                <SensorFormContainer sensor={sensor} />
              </Grid>
            ))}
            <Grid item xs={12} md={12} lg={12}>
              <PublicDashboardContainer node={node} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default observer(EditForm);
