import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import ActionsDropdown from '~/components/actions_dropdown';
import { MenuItemStyled, MenuGroupTitleStyled } from '~/components/actions_dropdown/styled';
import useMst from '~/hooks/use_mst';
import useSelection from '~/hooks/react_grid/use_selection';
import useExport from '~/hooks/react_grid/use_export';
import useChart from '~/hooks/use_chart';
import useActions from '~/components/actions_dropdown/context';
import ActionsModal from '~/pages/alerts/form_top/manage_modal/actions_modal';
import type { IAlert } from '~/mst/models/alert';
import useLocales from '~/hooks/use_locales';

function ActionsAcontainer({ alert }: { alert: IAlert }) {
  const { selection } = useSelection();
  const { t } = useLocales();
  const { alerts } = useMst();
  const { actions } = alerts;
  const { action, setAction } = useActions();
  const { startExport } = useExport();
  const { chartRef } = useChart();

  const canManage = Boolean(intersection(['activate', 'deactivate'], actions).length);
  const availableActions = intersection(...selection.map((id) => alerts.getById(id)?.actions));

  const handleActivate = useCallback(() => setAction('activate'), [setAction]);
  const handleDeactivate = useCallback(() => setAction('deactivate'), [setAction]);
  const handleDownloadTableData = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      startExport();
    },
    [startExport]
  );
  const handleDownloadChart = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      chartRef.current.chart.exportChart({
        filename: alert.name
      });
    },
    [chartRef, alert]
  );

  return (
    <>
      <ActionsDropdown btnSize="medium" variant="outlined">
        {canManage && [
          <MenuGroupTitleStyled key="manage-0">Manage</MenuGroupTitleStyled>,
          <MenuItemStyled key="manage-1" disabled={!includes(availableActions, 'activate')} onClick={handleActivate}>
            {t('base.buttons.activate')}
          </MenuItemStyled>,
          <MenuItemStyled key="manage-2" disabled={!includes(availableActions, 'deactivate')} onClick={handleDeactivate}>
            {t('base.buttons.deactivate')}
          </MenuItemStyled>
        ]}
        <MenuGroupTitleStyled>{t('base.buttons.download')}</MenuGroupTitleStyled>
        <MenuItemStyled onClick={handleDownloadChart}>Chart</MenuItemStyled>
        <MenuItemStyled onClick={handleDownloadTableData}>Table Data</MenuItemStyled>
      </ActionsDropdown>
      <ActionsModal
        open={action === 'activate' || action === 'deactivate'}
        type={action}
        toggleModal={() => setAction(null)}
        selected={selection.map((id) => alerts.getById(id))}
      />
    </>
  );
}

export default observer(ActionsAcontainer);
