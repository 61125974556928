/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Field as FinalField } from 'react-final-form';

interface Props {
  name: string;
}

export default function RFFHiddenInput(props: Props) {
  const { name, ...rest } = props;
  return (
    <FinalField name={name}>
      {({ input }) => {
        return <input {...rest} {...input} type="hidden" />;
      }}
    </FinalField>
  );
}
