import React, { useCallback, useState } from 'react';
import { Stack, Grid, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import useLocales from '~/hooks/use_locales';
import { ISensor } from '~/mst/models/device/sensor';
import { INode } from '~/mst/models/node';
import { Add } from '@mui/icons-material';
import useAuth from '~/hooks/use_auth';
import AvailableDevices from '~/mst/models/node/available_devices';

import DeviceFormContainer from '~/pages/device_edit/containers/device_form_container';
import SensorFormContainer from '~/pages/device_edit/containers/sensor_form_container';
import NewSensor from '~/pages/device_edit/containers/new_sensor_form_container';
import { AddWidgetPlaceholderStyled } from '~/pages/dashboard_view/widgets/styled';
import AdminSettingsContainer from '~/pages/device_edit/containers/admin_settings_container';

type EditFormProps = {
  node: INode;
};

function EditForm({ node }: EditFormProps) {
  const { t } = useLocales();
  const [isAddingSensor, setIsAddingSensor] = useState(false);
  const handleAddSensorButtonClick = useCallback(() => setIsAddingSensor(true), [setIsAddingSensor]);
  const handleCancelAddSensor = useCallback(() => setIsAddingSensor(false), [setIsAddingSensor]);
  const { auth } = useAuth();
  const isAdmin = auth.hasAdminDevice(node?.organization_id);
  const [availableDevices] = useState(AvailableDevices.create());

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <DeviceFormContainer node={node} />
          </Box>
          {isAdmin && (
            <Box mb={2}>
              <AdminSettingsContainer node={node} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            {isAddingSensor ? (
              <NewSensor availableDevices={availableDevices} node={node} cancelAddSensorForm={handleCancelAddSensor} />
            ) : (
              <Box>
                <AddWidgetPlaceholderStyled onClick={handleAddSensorButtonClick} key="new-sensor" sx={{ p: 3 }} data-testid="addNewSensorBtn">
                  <Add />
                  {t('thiamis.add_new_sensor')}
                </AddWidgetPlaceholderStyled>
              </Box>
            )}
          </Box>
          {node?.devices?.sensors?.map((sensor: ISensor) => (
            <Box mb={2} key={sensor._id}>
              <SensorFormContainer sensor={sensor} />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Stack>
  );
}

export default observer(EditForm);
