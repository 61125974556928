import { styled, Box, Stack, Card, Tabs, Tab } from '@mui/material';
import { transientOptions } from '~/utils/styled';

export const SIDEBAR_WIDTH = '275px';

export const MonitorHeadStyled = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row'
  },
  [theme.breakpoints.down('md')]: {
    ' > div:first-child': {
      minWidth: '85%'
    }
  }
}));

export const ChartContainerStyled = styled(Card)(({ theme }) => ({
  alignItems: 'stretch',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    height: '100%',
    minHeight: 0
  }
}));

export const SettingsStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(1)
  }
}));

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  minHeight: '45px',
  [theme.breakpoints.down('lg')]: {
    alignSelf: 'center'
  }
}));

export const TabStyled = styled(Tab)(({ theme }) => ({
  margin: 0,
  minWidth: '0',
  minHeight: '45px',
  fontSize: '14px',
  padding: theme.spacing(2),
  '&.MuiButtonBase-root:not(:last-of-type)': {
    marginRight: '0'
  },
  [theme.breakpoints.down('lg')]: {
    '.tab-label': {
      display: 'none'
    }
  }
}));

export const TopbarPanelStyled = styled(Stack)(({ theme, $isBorderless = false }) => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  paddingRight: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse'
  },
  ...($isBorderless
    ? {
        borderRight: 'none'
      }
    : {
        borderRight: `1px solid ${theme.palette.grey[100]}`
      })
}));

export const TabContainerStyled = styled(
  Box,
  transientOptions
)(({ theme, $isExpanded, $width }) => ({
  maxWidth: '100%',
  flex: 1,
  transition: 'all .2s ease',
  [theme.breakpoints.up('md')]: {
    ...($isExpanded && {
      maxWidth: `calc(100% - ${$width || SIDEBAR_WIDTH})`
    })
  },
  '@media print': {
    maxWidth: '100%'
  }
}));

export const SidebarHeaderStyled = styled(
  Stack,
  transientOptions
)(({ theme, $width }) => ({
  width: '100%',
  maxWidth: $width || SIDEBAR_WIDTH,
  maxHeight: '47px',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    justifyContent: 'center'
  }
}));
