import kebabCase from 'lodash/kebabCase';
import { memo, useMemo } from 'react';
import { INode } from '~/mst/models/node';
import { AQ_STATUSES } from '~/utils/constants';

const DeviceIcon = memo(({ node }: { node: INode }) => {
  const hasAq = useMemo(() => AQ_STATUSES.includes(node?.status), [node?.status]);

  if (hasAq) {
    return (
      <div className={`aq-badge state-${node?.status}`}>
        <img src="/static/icons/svg/aq.svg" alt="" className="aq-icon" />
        <div className="aq-body_name">Air Quality</div>
        <div className="aq-body_value">{node?.status}</div>
      </div>
    );
  }

  return <img src={`/static/icons/devices/${kebabCase(node?.thiamis?.name)}.svg`} alt="" className="widget-map_icon__thiamis" />;
});

export default DeviceIcon;
