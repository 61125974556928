/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';

import { IAvailableDevicesModel } from './model';

function mapById(data) {
  return data.reduce((acc: any, item: any) => {
    const { name, connection } = item;
    if (!acc[name]) {
      acc[name] = { name, connections: [] };
    }
    const [, com_port, com_type, address] = connection.split('.');
    acc[name].connections.push({ com_port, com_type, address });
    return acc;
  }, {});
}

export default (self: IAvailableDevicesModel) => ({
  fetch: flow(function* fetch(params: { _id: string; organization_id: string; serial: string; devices: any[] }) {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.nodes.fetchAvailableDevices, params);
      applySnapshot(self, {
        models: mapById(data)
      });
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});
