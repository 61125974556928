import { types as t, Instance, SnapshotOut, flow, applySnapshot, getIdentifier } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import api from '~/api';

const DeviceDefaultWifiModel = t
  .compose(
    t.model({
      node_id: t.identifier,
      wifi_ssid: t.maybeNull(t.string),
      wifi_password: t.maybeNull(t.string)
    }),
    Fetchable({ cache: { lifeTime: 300 } })
  )
  .actions((self) => ({
    fetch: flow(function* fetch() {
      try {
        self.startFetching();
        const { data } = yield self.sync(api.nodes.getDefaultWifi, getIdentifier(self));
        applySnapshot(self, { ...data, node_id: getIdentifier(self) });
        self.finishFetching();
      } catch (error) {
        self.failFetching(error);
      }
    })
  }));

export interface IDeviceDefaultWifiModel extends Instance<typeof DeviceDefaultWifiModel> {}
export interface IDeviceDefaultWifiModelSnapshotOut extends SnapshotOut<typeof DeviceDefaultWifiModel> {}

export default DeviceDefaultWifiModel;
