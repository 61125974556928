/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import type { IDeviceMonitor } from '.';

export default (self: IDeviceMonitor) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      yield self.nodeWidget.fetch();
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  setSelectedTab(tab) {
    self.selected_tab = tab;
  },
  toggleParameter(dataPointId) {
    const params = `selected_params_${self.selected_tab}`;
    if (self.params[params]?.some((id) => id === dataPointId)) {
      const newParams = self.params[params].filter((id) => id !== dataPointId);
      self.params[params] = newParams;
      if (self.selected_tab === 'chart') {
        self.params.selected_params_table = newParams;
      }
    } else {
      self.params[params]?.push(dataPointId);
      if (self.selected_tab === 'chart') {
        self.params.selected_params_table?.push(dataPointId);
      }
    }
  }
});
