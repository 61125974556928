import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';

import Container from './container';

type AddWidgetsContainerProps = {
  isVisible: boolean;
  handleVisible: () => void;
};

function AddWidgetsContainer({ isVisible, handleVisible }: AddWidgetsContainerProps) {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isVisible]);

  return (
    <>
      <Button variant="outlined" onClick={handleVisible} size="small" sx={{ minWidth: 'auto', px: '8px', py: '4px' }}>
        <Add fontSize="small" />
        Add Widget
      </Button>
      <Container isVisible={isVisible} onHide={handleVisible} />
    </>
  );
}

export default AddWidgetsContainer;
