import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import ReportModel from '~/mst/models/abstract/report';
import ChartTableWidget from './chart_table_widget';

const AlertMonitorModel = t.compose(
  t.model({
    alert_id: t.string,
    chartTableWidget: t.optional(ChartTableWidget, {})
  }),
  Fetchable(),
  ReportModel
);

export interface IAlertMonitorModel extends Instance<typeof AlertMonitorModel> {}

export default AlertMonitorModel;
