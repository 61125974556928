/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import Fetchable from '~/mst/models/abstract/fetchable';
import CollectionMap from '~/mst/models/abstract/collection_map';
import AvailableDeviceModel from './available_device';

const AvailableDevicesModel = t.compose(CollectionMap(AvailableDeviceModel), Fetchable({ cache: { lifeTime: 300 } })).named('AvailableDevicesModel');

export interface IAvailableDevicesModel extends Instance<typeof AvailableDevicesModel> {}

export default AvailableDevicesModel;
