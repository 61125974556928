import { types as t, Instance } from 'mobx-state-tree';

const RangeModel = t.model({
  timezone: t.maybeNull(t.string),
  from: t.frozen(),
  to: t.frozen(),
  is_pinned: t.optional(t.boolean, false)
});

export interface IRangeModel extends Instance<typeof RangeModel> {}

export default RangeModel;
