/* eslint-disable no-param-reassign */
import { IRangeModel } from './model';

export default (self: IRangeModel) => ({
  setTimezone(tz) {
    self.timezone = tz;
  },
  setFrom(from) {
    self.from = from;
  },
  setTo(to) {
    self.to = to;
  },
  togglePinned() {
    self.is_pinned = !self.is_pinned;
  }
});
