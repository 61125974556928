import React, { useCallback, useState, SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Stack, IconButton } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AirIcon from '@mui/icons-material/Air';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';

import { IDeviceMonitor } from '~/pages/device_monitor/store';
import useLocales from '~/hooks/use_locales';

import { MonitorHeadStyled, TabsStyled, TabStyled, ChartContainerStyled, SidebarHeaderStyled, TopbarPanelStyled, TabContainerStyled } from './styled';
import { TabPanel } from './components';

import Chart from './chart';
import WindChart from './wind_chart';
import Table from './table';
import Sidebar from './sidebar';
import { TABS } from '../store/model';

function MonitorChart({ monitor }: { monitor: IDeviceMonitor }) {
  const { t } = useLocales();
  const [isExpanded, setIsExpanded] = useState(true);

  const handleCollapse = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const hasWindDataPoint = monitor?.nodeWidget?.node?.data_points?.toArray.some((dp) => dp.isWind);

  return (
    <ChartContainerStyled>
      <MonitorHeadStyled>
        <TopbarPanelStyled>
          <TabsStyled
            sx={{ displayPrint: 'none' }}
            value={monitor.tabIndex}
            onChange={(event: SyntheticEvent, newValue: number) => {
              monitor.setSelectedTab(Object.values(TABS).find(({ index }) => index === newValue).name);
            }}
            variant="scrollable"
            allowScrollButtonsMobile
          >
            <TabStyled icon={<ShowChartIcon />} label={<span className="tab-label">Chart</span>} />
            <TabStyled icon={<TableRowsIcon />} label={<span className="tab-label">Table</span>} />
            {hasWindDataPoint && <TabStyled icon={<AirIcon />} label={<span className="tab-label">Wind Rose</span>} />}
          </TabsStyled>
        </TopbarPanelStyled>

        <SidebarHeaderStyled direction="row" spacing={2} sx={{ p: 0 }}>
          <IconButton onClick={handleCollapse!} title={t('device_monitor.toggle_parameters')} sx={{ displayPrint: 'none' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </SidebarHeaderStyled>
      </MonitorHeadStyled>
      <Stack direction="row">
        <TabContainerStyled $isExpanded={isExpanded}>
          <TabPanel value={monitor.tabIndex} index={TABS.GRAPH.index}>
            <Chart report={monitor} />
          </TabPanel>
          <TabPanel value={monitor.tabIndex} index={TABS.WIND_ROSE_GRAPH.index}>
            <WindChart report={monitor} />
          </TabPanel>
          <TabPanel value={monitor.tabIndex} index={TABS.TABLE.index} sx={{ p: 0, maxHeight: 600, overflow: 'auto' }}>
            <Table report={monitor} />
          </TabPanel>
        </TabContainerStyled>

        <Sidebar onToggleCollapse={handleCollapse!} isExpanded={isExpanded} report={monitor} />
      </Stack>
    </ChartContainerStyled>
  );
}

export default observer(MonitorChart);
