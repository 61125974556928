/* eslint-disable @typescript-eslint/naming-convention */
import { DateTime, Duration } from 'luxon';
import isNumber from 'lodash/isNumber';
import isNull from 'lodash/isNull';
import isNaN from 'lodash/isNaN';
import I18n from '~/utils/i18n';
import type { IRangeModel } from './model';

export const DATE_TIME_FORMAT = 'LL/dd/yyyy HH:mm';

const PERIODS = ['today', 'yesterday', 'this_week', 'last_week', 'this_month', 'last_month'];

export default (self: IRangeModel) => {
  function getTimeNow(now = DateTime.now()) {
    if (self.timezone) {
      return now.setZone(self.timezone);
    }
    return now;
  }
  function getPeriodDateTime(period) {
    switch (period) {
      case 'today':
        return getTimeNow().startOf('day');
      case 'yesterday':
        return getTimeNow().startOf('day').minus({ days: 1 });
      case 'this_week':
        return getTimeNow().startOf('week');
      case 'last_week':
        return getTimeNow().startOf('week').minus({ weeks: 1 });
      case 'this_month':
        return getTimeNow().startOf('month');
      case 'last_month':
        return getTimeNow().startOf('month').minus({ months: 1 });
      default:
        console.warn(`Unknown period ${period}`);
        return getTimeNow();
    }
  }
  function fromTs(now = getTimeNow()) {
    if (typeof self.from === 'number') {
      return getTimeNow(DateTime.fromMillis(self.from)).toMillis();
    }
    if (PERIODS.includes(self.from)) {
      return getPeriodDateTime(self.from).toMillis();
    }
    try {
      return now.minus(Duration.fromObject(self.from)).toMillis();
    } catch {}
    return now.minus(Duration.fromObject({ hours: 3 })).toMillis();
  }
  function toTs(now = getTimeNow()) {
    if (!self.to) {
      return now.toMillis();
    }
    if (typeof self.to === 'number') {
      return getTimeNow(DateTime.fromMillis(self.to)).toMillis();
    }
    if (PERIODS.includes(self.to)) {
      return getPeriodDateTime(self.to).toMillis();
    }
    try {
      return now.minus(Duration.fromObject(self.to)).toMillis();
    } catch {}
    return now.toMillis();
  }
  return {
    get toString() {
      if (isNumber(self.from)) {
        const parsedFrom = getTimeNow(DateTime.fromMillis(self.from));
        const parsedTo = isNull(self.to) || isNaN(self.to) ? getTimeNow() : getTimeNow(DateTime.fromMillis(self.to));
        return `${parsedFrom.toFormat(DATE_TIME_FORMAT)} - ${parsedTo.toFormat(DATE_TIME_FORMAT)}`;
      }
      if (PERIODS.includes(self.from)) {
        return I18n.t(`datepicker.ranges.${self.from}`, { defaultValue: self.from });
      }
      if (self.from) {
        const from = getTimeNow().minus(Duration.fromObject(self.from));
        return `${from.toFormat(DATE_TIME_FORMAT)} - ${getTimeNow().toFormat(DATE_TIME_FORMAT)}`;
      }
      return '';
    },
    get fromTs() {
      return fromTs();
    },
    get toTs() {
      return toTs();
    },
    fetchParams() {
      return {
        from: fromTs(),
        ...(self.to && { to: toTs() })
      };
    }
  };
};
