/* eslint-disable no-param-reassign */
import { flow, applySnapshot } from 'mobx-state-tree';
import api from '~/api';
import { toMapById } from '~/mst/utils';
import { isEmpty } from 'lodash';
import type { INodeWidgetModel } from './model';

export default (self: INodeWidgetModel) => ({
  fetch: flow(function* fetch(params = {}) {
    try {
      self.startFetching();
      const payload = { node_id: self.node_id, includes: ['profiles', 'configuration'], last: 20 };
      const { data: nodes } = yield self.sync(api.search.nodes, payload);
      if (!isEmpty(self.selected_data_point_ids)) {
        const { data: dataPoints } = yield self.sync(api.search.dataPoints, { data_point_id: self.selected_data_point_ids, ...params });
        applySnapshot(self.nodes, {
          models: toMapById(
            nodes.map((node) => ({
              ...node,
              data_points: node.data_points.map((dataPoint) => ({ ...dataPoint, ...(dataPoints.find(({ _id }) => _id === dataPoint._id) || {}) }))
            }))
          )
        });
      } else {
        applySnapshot(self.nodes, {
          models: toMapById(nodes)
        });
      }
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  }),
  setFilter(value) {
    self.filter = value;
  }
});
