import React, { useState } from 'react';
import { getIdentifier } from 'mobx-state-tree';
import type { INode } from '~/mst/models/node';
import DefaultWifi from '~/mst/models/node/default_wifi';
import Modal from '~/components/modal';
import useLocales from '~/hooks/use_locales';
import ModalContent from './content';

type DefaultWifiModalProps = {
  open: boolean;
  onClose: () => void;
  node: INode;
};

export function DefaultWifiModal({ node, open, onClose }: DefaultWifiModalProps) {
  const { t } = useLocales();
  const nodeId = getIdentifier(node);

  const [defaultWifi] = useState(
    DefaultWifi.create({
      node_id: nodeId
    })
  );

  return (
    <Modal open={open} handleClose={onClose} title={t('thiamis.default_wifi_dialog.title')}>
      <ModalContent defaultWifi={defaultWifi} />
    </Modal>
  );
}

export default DefaultWifiModal;
