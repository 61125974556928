import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV1 as Model } from '~/mst/models/abstract/model';
import NodeBase from '~/mst/models/abstract/node_base';
import { NodeTypes } from '~/mst/models/abstract/node_base/types';
import DataPointsNode from '~/mst/models/data_points/node';
import Syncable from '~/mst/models/abstract/syncable';
import Fetchable from '~/mst/models/abstract/fetchable';
import Devices from '~/mst/models/devices';

const NodeModel = t
  .compose(
    t.model({
      _type: NodeTypes.Thiamis,
      last_online: t.maybeNull(t.frozen(t.number)),
      interval: t.maybeNull(t.number),
      endpoint: t.maybeNull(t.frozen(t.string)),
      devices: t.maybeNull(Devices),
      data_points: t.maybeNull(DataPointsNode),
      status: t.frozen(),
      calibrations: t.frozen(),
      shared_to: t.maybeNull(t.array(t.string)),
      user_metadata: t.frozen(),
      leased_to: t.frozen(),
      templates: t.frozen()
    }),
    Model,
    NodeBase,
    Syncable,
    Fetchable({ cache: true })
  )
  .named('Node');

export interface INodeModel extends Instance<typeof NodeModel> {}
export interface INodeModelSnapshotOut extends SnapshotOut<typeof NodeModel> {}

export default NodeModel;
