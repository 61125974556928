import React, { useCallback, useState, SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Stack, IconButton } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';
import BugReportIcon from '@mui/icons-material/BugReport';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';

import { IDeviceMonitor } from '~/pages/device_monitor/store';

import { TabPanel } from '~/pages/device_monitor/monitor_chart/components';
import {
  MonitorHeadStyled,
  TabsStyled,
  TabStyled,
  SidebarHeaderStyled,
  TopbarPanelStyled,
  TabContainerStyled,
  ChartContainerStyled
} from '~/pages/device_monitor/monitor_chart/styled';
import { TABS } from '../store/model';
import DebugSidebar from './debug_tab/sidebar';
import DebugTab from './debug_tab';
import FilesTab from './files_tab';

const SIDEBAR_WIDTH = 325;

function MonitorChart({ report }: { report: IDeviceMonitor }) {
  const [tab, setTab] = useState(TABS.DEBUG.index);
  const [isExpanded, setIsExpanded] = useState(true);

  const handleCollapse = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleTabChange = useCallback((event: SyntheticEvent, newValue: number) => {
    report.setSelectedTab(Object.values(TABS).find(({ index }) => index === newValue).name);
    setTab(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChartContainerStyled>
      <MonitorHeadStyled>
        <TopbarPanelStyled>
          <TabsStyled value={tab} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
            <TabStyled icon={<BugReportIcon />} label={<span className="tab-label">Debug</span>} />
            <TabStyled icon={<SourceIcon />} label={<span className="tab-label">Files</span>} />
          </TabsStyled>
        </TopbarPanelStyled>

        <SidebarHeaderStyled direction="row" spacing={2} $width={SIDEBAR_WIDTH}>
          <IconButton onClick={handleCollapse!} title="Toggle Parameters">
            <Icon icon={menu2Fill} />
          </IconButton>
        </SidebarHeaderStyled>
      </MonitorHeadStyled>
      <TabPanel value={tab} index={TABS.DEBUG.index} sx={{ p: 0 }}>
        <Stack direction="row">
          <TabContainerStyled $isExpanded={isExpanded} width={SIDEBAR_WIDTH}>
            <DebugTab report={report} />
          </TabContainerStyled>
          <DebugSidebar onToggleCollapse={handleCollapse!} isExpanded={isExpanded} width={SIDEBAR_WIDTH} report={report} />
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index={TABS.FILES.index} sx={{ p: 0 }}>
        <FilesTab report={report} isExpanded={isExpanded} sidebarWidth={SIDEBAR_WIDTH} handleCollapse={handleCollapse} />
      </TabPanel>
    </ChartContainerStyled>
  );
}

export default observer(MonitorChart);
