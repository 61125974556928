/* eslint-disable no-param-reassign */
import type { IChartTableWidgetModel } from './model';

export default (self: IChartTableWidgetModel) => ({
  toggleShowZones() {
    self.show_zones = !self.show_zones;
  },
  toggleShowDataGaps() {
    self.show_data_gaps = !self.show_data_gaps;
  }
});
