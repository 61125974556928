/* eslint-disable no-param-reassign */
import { types as t, Instance } from 'mobx-state-tree';
import ReportModel from '~/mst/models/abstract/report';
import Widgets from './widgets';

export const TABS = {
  DEBUG: {
    name: 'debug',
    index: 0
  },
  FILES: {
    index: 1,
    name: 'files'
  }
};

const DeviceAdminModel = t.compose(
  t.model({
    selected_tab: t.optional(
      t.enumeration(
        'Tab',
        Object.entries(TABS).map(([, { name }]) => name)
      ),
      TABS.DEBUG.name
    ),
    widgets: t.optional(Widgets, [])
  }),
  ReportModel
);

export interface IDeviceAdminModel extends Instance<typeof DeviceAdminModel> {}

export default DeviceAdminModel;
