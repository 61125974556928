import { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { IDataPointNodeSnapshotOut } from '~/mst/models/data_point/node/default';
import type { IDashboard } from '~/mst/models/dashboard';
import useSubscribable from '~/hooks/use_subscribable_mst';

const useDashboardSubscribe = (dashboard: IDashboard) => {
  const [dataPointsWhenPaused, setDataPointsWhenPaused] = useState<IDataPointNodeSnapshotOut[]>([]);
  function setMeasurements(dataPoints) {
    dataPoints.forEach((dataPoint) => {
      const { _id: dataPointId, measurements } = dataPoint;
      dashboard?.widgets.toArray.forEach((widget) =>
        widget.nodes?.getNodeByDataPointId(dataPointId)?.data_points?.getById(dataPointId)?.updateMeasurements(measurements)
      );
    });
  }

  useEffect(
    () =>
      autorun(() => {
        if (dashboard.is_updates_on) {
          setMeasurements(dataPointsWhenPaused);
          if (dataPointsWhenPaused.length) {
            setDataPointsWhenPaused([]);
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataPointsWhenPaused]
  );

  const { subscribe, unsubscribe } = useSubscribable((dataPoints) => {
    if (dataPoints) {
      if (dashboard.is_updates_on) {
        setMeasurements(dataPoints);
      } else {
        setDataPointsWhenPaused((prevDataPointsWhenPaused) => {
          return [...prevDataPointsWhenPaused, ...dataPoints];
        });
      }
    }
  });

  useEffect(() => {
    subscribe(dashboard?.nodeIds);
    return () => unsubscribe();
  }, [dashboard?.nodeIds, subscribe, unsubscribe]);
};

export default useDashboardSubscribe;
