import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { IReport } from '~/mst/models/report';
import useLocales from '~/hooks/use_locales';
import useExport from '~/hooks/react_grid/use_export';

function DownloadButton({ report }: { report: IReport }) {
  const { t } = useLocales();
  const { startExport } = useExport();

  const handleDownload = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (report.isTableView) {
        startExport();
      } else {
        window.print();
      }
    },
    [startExport, report.isTableView]
  );

  return (
    <Box onClick={handleDownload} title={t('monitor.download_chart')}>
      {`${report.isChartView ? t('monitor.download_chart_pdf') : t('monitor.download_table_data')}`}
    </Box>
  );
}

export default observer(DownloadButton);
