import React from 'react';
import { observer } from 'mobx-react-lite';

import useFetch from '~/hooks/use_fetch';
import type { INode } from '~/mst/models/node';
import ThiamisDeviceLayout from './thiamis_layout';
import AirthinxDeviceLayout from './airthinx_layout';
import HealthwayDeviceLayout from './healthway_layout';

function Layout({ node }: { node: INode }) {
  useFetch(node, { includes: ['configuration', 'profiles', 'calibrations'], last: 1 });

  if (node?.isHealthway) {
    return <HealthwayDeviceLayout node={node} />;
  }
  if (node?.isAirthinx) {
    return <AirthinxDeviceLayout node={node} />;
  }
  return <ThiamisDeviceLayout node={node} />;
}

export default observer(Layout);
